import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:py-2 py-10">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[5px] md:bottom-[100px] lg:bottom-[90px] md:text-start  text-center text-white">
                    <div className="w-full md:w-[70%] md:pt-0 pt-44">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="md:text-start text-center">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                    <div className="w-full md:w-[30%] flex md:pb-9 pb-5 justify-center items-center">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/video1-f8800.appspot.com/o/20%20years.png?alt=media&token=521e3981-f329-4b31-a2ac-e8a5c4d7bf85"
                            alt="years of experience"
                            className="w-60 md:w-96"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;